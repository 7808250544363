import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroSection from "../components/pages/ContactUs/HeroSection"
import {
  contactFromSection,
  heroSection,
} from "../Content/ContactUs"
import ContactFormSection from "../components/pages/ContactUs/ContactFormSection"
import FaqsSection from "../components/common/FaqsSection"
import { faqs } from "../Content/Home"

const ContactUs = () => (
  <Layout>
    <Seo
      title="Contact Us"
      description={`Contact the Synkli support team for any inquiries, assistance, or feedback. We're here to help you with your finance management needs. Contact us today!`}
    />

    <HeroSection
      title={heroSection.title}
      description={heroSection.description}
    />

    <div className="synkli-section--horizontal-space !mt-5 md:!mt-10 !mb-10 md:!mb-20">
      <hr />
    </div>

    <ContactFormSection
      iconsImage={contactFromSection.iconsImage}
      description={contactFromSection.description}
    />

    <FaqsSection faqs={faqs} />

  </Layout>
)

export default ContactUs
